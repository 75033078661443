import React from 'react';
import {graphql, Link} from 'gatsby';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import i18n from 'data/i18n.en.json';


export default class CareersPage extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        const {jobDetails, careersGeneral} = this.props.data;
        return (
            <Layout className="careers">
                <Meta
                    description={jobDetails.acf.role_description}
                    title={jobDetails.title}
                />
                <aside>
                    <Link to={careersGeneral.path}>
                        <Graphic name="iconCareers"/>
                        {i18n.careers.allJobs}
                    </Link>
                    <article
                        dangerouslySetInnerHTML={{__html:
                            careersGeneral.acf.about_application,
                        }}
                    />
                    <a
                        className="cta"
                        href={`mailto:${i18n.careers.email}?subject=${jobDetails.title} Role`}
                    >
                        {i18n.careers.apply}
                    </a>
                </aside>
                <section>
                    <h1>{jobDetails.title}</h1>
                    {jobDetails.acf.location &&
                        <p>
                            <Graphic name="iconLocation"/>
                            {jobDetails.acf.location}
                        </p>
                    }
                    <article
                        dangerouslySetInnerHTML={{__html:
                            careersGeneral.acf.about_hs,
                        }}
                    />
                    <h2>{i18n.careers.aboutRole}</h2>
                    <Markdown>{jobDetails.acf.role_description}</Markdown>
                    <h2>{i18n.careers.responsibilities}</h2>
                    <Markdown>{jobDetails.acf.responsibilities}</Markdown>
                    <h2>{i18n.careers.skills}</h2>
                    <Markdown>{jobDetails.acf.skills}</Markdown>
                    <h2>{i18n.careers.perks}</h2>
                    <Markdown>{careersGeneral.acf.perks}</Markdown>
                    <article>
                        <p dangerouslySetInnerHTML={{__html:
                            careersGeneral.acf.fair_employment,
                        }}
                        />
                    </article>
                </section>
            </Layout>
        );
    }
}

export const query = graphql`
    query($id: Int!) {
        jobDetails: wordpressWpCareers(wordpress_id: {eq: $id}) {
            acf {
                location
                role_description
                responsibilities
                skills
            }
            title
        }
        careersGeneral: wordpressPage(wordpress_id: {eq: 108}) {
            acf {
                about_application
                about_hs
                fair_employment
                perks
            }
            path
        }
    }
`;
